import { DataLayer } from "../../libs/";

export const postTogglePinned =
  ({ id, status, value }) =>
  () => {
    return DataLayer.post({
      path: `/comments/${id}/pin.json`,
      body: {
        pinned_status: status,
        value: value,
      },
      loader: true,
    });
  };
